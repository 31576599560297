import CollectionsOverlay from "@/components/CollectionsOverlay";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import MaintenanceMode from "@/components/MaintenanceMode";
import SalePrompt from "@/components/SalePrompt";
import TimerList from "@/components/TimerList";
import useApp from "@/hooks/useApp";
import useIsAuthPage from "@/hooks/useIsAuthPage";
import { useStores } from "@/hooks/useStores";
import { pushEvent } from "@/lib/gtm";
import AdSpot from "@/src/ads/components/AdSpot";
import ListsOverlay from "@/src/lists/components/ListsOverlay";
import clsx from "clsx";
import { enableStaticRendering, observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect, useRef, useState, useContext } from "react";
import { useHeaderContext } from "@/contexts/HeaderContext";
import posthog from "posthog-js";
import { SessionContext } from "@/contexts/SessionContext";
import useGTM from "@/hooks/useGTM";

const Layout = ({
  children,
  primaryNavigation,
  promoBanner,
  navigation,
  breadcrumbs,
}) => {
  const router = useRouter();
  const gtm = useGTM();

  enableStaticRendering(typeof window === "undefined");
  const { setHoveredTab, hoveredTab } = useHeaderContext();

  const isMobApp = useApp();

  const { menuStore, authStore } = useStores();
  const [currencyFromQueryString, setCurrencyFromQueryString] = useState("");

  const ref = useRef(null);

  /*
   * Authentication and user data
   */

  const { currentUser } = authStore;

  // resetting hover state on route change
  useEffect(() => {
    const handleRouteChange = (url) => {
      setHoveredTab(null);
      gtm.pushEvent("pageview", {
        path: url,
      });
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, setHoveredTab]);

  /* Fetch the current user */
  useEffect(() => {
    authStore.fetchCurrentUser();
  }, [authStore]);

  /* Fetch the current user's menus */
  useEffect(() => {
    if (authStore.isLoggedIn && !currentUser.listsMigrated) {
      menuStore.fetch();
    }
  }, [authStore.isLoggedIn, currentUser?.listsMigrated, menuStore]);

  useEffect(() => {
    if (router.isReady && router.query["campaign_currency"]) {
      setCurrencyFromQueryString(router.query["campaign_currency"] as string);
    }
  }, [router.isReady]);

  const { sessionId, guestId, utmString } = useContext(SessionContext);

  useEffect(() => {
    const status = authStore.isLoggedIn ? "loggedIn" : "guest";
    const userId = authStore.isLoggedIn ? currentUser.id : null;
    const data = {
      status,
      userId,
      planId: authStore.isLoggedIn ? currentUser.plan : 0,
    };
    pushEvent("loginStatus", data);
  }, [authStore, authStore.isLoggedIn]);

  useEffect(() => {
    // send posthog feature flags to GTM
    posthog?.onFeatureFlags((flags, flagVariants) => {
      gtm.pushEvent("feature_flags_initialised", {
        active_feature_flags: flags,
        ...flagVariants,
      });
    });

    /* Fire a user identified event whenever auth is updated */
    if ((authStore.currentUser || guestId) && sessionId) {
      gtm.pushEvent("user_identified");
      gtm.pushEvent("pageview", {
        path: router.asPath,
      });
    }
  }, [authStore.currentUser, sessionId, guestId]);

  const isAuthPage = useIsAuthPage();

  /*
   * Auth modal
   */

  /* On route change, close down auth modals */
  useEffect(() => {
    const handleRouteChange = () => {
      authStore.setIsAuthActive(false);
      authStore.setIsClosable(true);
      authStore.setAuthStatus(null);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => router.events.off("routeChangeStart", handleRouteChange);
  }, [authStore, router.events]);

  useEffect(() => {
    // Change (--header-height) to the 0px if we're in the app
    if (isMobApp || isAuthPage) {
      document.documentElement.style.setProperty("--header-height", "0px");
    }
  }, [isMobApp, isAuthPage]);

  /*
   * Maintenance mode
   */

  if (
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "on" &&
    !(
      authStore.isLoggedIn &&
      (authStore.currentUser.id === "1" ||
        authStore.currentUser.id === "3687381")
    )
  )
    return <MaintenanceMode />;

  return (
    <>
      {process.env.NEXT_PUBLIC_ADS_PROVIDER === "hashtaglabs" && (
        <>
          <AdSpot spot="htlad-ros_adhesion" />
          <AdSpot spot="htlad-ros_1x1" />
        </>
      )}

      <div className={clsx("Layout", "print:!pb-0")} ref={ref}>
        {!isAuthPage && !isMobApp && (
          <Header
            promoBanner={promoBanner}
            displayCurrency={currencyFromQueryString}
            primaryNavigation={primaryNavigation}
            isMobApp={isMobApp}
            navigation={navigation}
            breadcrumbs={breadcrumbs}
          />
        )}

        <div
          className={clsx("Layout__content", "relative z-20", {
            "mt-0": isMobApp,
          })}
        >
          {!isAuthPage && !isMobApp && (
            <SalePrompt displayCurrency={currencyFromQueryString} />
          )}
          <main
            className={clsx(
              "Layout__main",
              "min-h-dvh-ex-header relative z-10 flex h-full flex-col bg-white"
            )}
          >
            {children}
          </main>
        </div>

        {!isAuthPage && !isMobApp && (
          <div className={clsx("Layout__footer", "")}>
            <Footer />
          </div>
        )}
      </div>

      <TimerList />

      {!currentUser?.listsMigrated && <CollectionsOverlay />}

      {currentUser?.listsMigrated && <ListsOverlay />}

      {/* <SearchOverlay theme={"light"}  isMobApp={isMobApp} /> */}
    </>
  );
};

Layout.displayName = "Layout";

export default observer(Layout);
