import { ChefHat } from "@/components/Icons";
import { pushEvent } from "@/lib/gtm";
import variantsData from "@/src/paywall/variants.json";
import Button from "@components/Button";
import { SubscriptionContext } from "@contexts/SubscriptionContext";
import {
  BookmarkIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  LockOpenIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactModal from "react-modal";
import posthog from "posthog-js";
import usePayWall from "@/hooks/usePaywall";

ReactModal.setAppElement("#__next");

export const PaywallContext = React.createContext(null);

export const PaywallContextProvider = ({ children, isMethod = true }) => {
  const defaults = {
    isOpen: false,
    isClosable: false,
    variant: "default",
    title: "This recipe",
  };

  const router = useRouter();

  const [isOpen, setIsOpen] = useState(defaults.isOpen);
  const [isClosable, setIsClosable] = useState(defaults.isClosable);
  const [title, setTitle] = useState(defaults.title);
  const [variant, setVariant] = useState(defaults.variant);

  // This will get populated by a call to the API through the
  const [variants, setVariants] = useState(variantsData.variants);

  const calculateAccessLevel = (userPlan) => {
    if (userPlan === 2) {
      return "2";
    } else if (userPlan === 1 || userPlan === 3 || userPlan === 4) {
      return "1";
    } else {
      return "0";
    }
  };
  const { plan } = useContext(SubscriptionContext);
  const accessLevel = calculateAccessLevel(plan);

  const iconOpts = {
    bookmark: BookmarkIcon,
    chefsHat: ChefHat,
    calendar: CalendarDaysIcon,
  };

  const srcOpts = {
    neon: "/images/modal-neon.png",
    blaze: "/images/modal-blaze.png",
    green: "/images/modal-green.png",
  };

  // When a paywall opens, fire an event
  useEffect(() => {
    if (isOpen) {
      pushEvent("paywall_modal_hit", {
        variant,
        accessLevel,
      });
    }
  }, [isOpen, variant, accessLevel]);

  const activeVariant = useMemo(() => {
    if (!variants) return null;
    // Try to find the variant that matches the 'variant' variable
    const foundVariant = variants.find(({ name }) => name === variant);
    // If no variant is found, return the 'default' variant
    return foundVariant || variants.find(({ name }) => name === "default");
  }, [variants, variant]);

  const imgSrc = srcOpts[activeVariant?.altTheme ? "blaze" : "neon"] ?? null;
  const Icon = iconOpts[activeVariant?.icon] ?? null;

  const heading = activeVariant?.title.replace(/#RecipeTitle/g, title);

  const value = {
    isOpen,
    setIsOpen,
    isClosable,
    setIsClosable,
    title,
    setTitle,
    variant,
    setVariant,
    variants,
    setVariants,
  };
  const ref = useRef();

  // When route changes, reset the modal back
  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(defaults.isOpen);
      setIsClosable(defaults.isClosable);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  const handleRequestClose = () => {
    if (isClosable) {
      setIsOpen(false);
    } else {
      const previousUrl = sessionStorage.getItem("previousUrl") || "/";
      router.push(previousUrl);
    }
  };

  const content = isMethod
    ? variants?.inRecipeMethod
    : variants?.inRecipeIngredients;

  const goToSignUp = () => {
    router.push(`/signup`);
  };

  return (
    <PaywallContext.Provider value={value}>
      {/* The main page */}
      {children}
      {/*The modal for Mob+ upsells*/}
      <ReactModal
        closeTimeoutMS={250}
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        className={{
          base: "w-full outline-none md:max-w-[500px]",
          afterOpen: "Modal__content--afterOpen ",
          beforeClose: "Modal__content--beforeClose ",
        }}
        overlayClassName={{
          base: "Modal__overlay !p-0",
          afterOpen: "Modal__overlay--afterOpen",
          beforeClose: "Modal__overlay--beforeClose",
        }}
        portalClassName="Modal__portal"
        bodyOpenClassName="Body--modalOpen"
        htmlOpenClassName="Html--modalOpen"
        contentRef={(contentRef) => {
          ref.current = contentRef;
        }}
        onAfterOpen={() => {
          disableBodyScroll(ref.current);
        }}
        onAfterClose={() => {
          clearAllBodyScrollLocks();
        }}
      >
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{
                opacity: 0,
                scale: 0.95,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0.95,
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
              className="flex cursor-pointer flex-col md:flex-row"
              onClick={goToSignUp}
            >
              <div
                className={`relative mx-5 mb-5 w-[calc(100vw-40px)] rounded-3xl p-5 text-white drop-shadow-[0_8px_32px_rgba(0,0,0,0.5)] md:mx-0 md:mb-0 md:w-full md:p-6`}
              >
                {imgSrc && (
                  <Image
                    src={imgSrc}
                    alt=""
                    layout="fill"
                    objectFit="cover"
                    role="presentation"
                    sizes="(max-width: 767px) 100vw, 480px"
                    className="pointer-events-none absolute left-0 top-0 block h-full w-full rounded-[20px]"
                  />
                )}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRequestClose();
                  }}
                  className="absolute right-0 top-0 z-10 m-6 flex h-8 w-8 items-center justify-center rounded-full bg-white text-zinc-800 md:h-10 md:w-10"
                >
                  <XMarkIcon className="w-6 md:w-7" />
                </button>
                <div className="relative space-y-4">
                  {Icon && <Icon className="h-16 w-16 md:h-20 md:w-20" />}
                  <div className="space-y-6 md:space-y-8">
                    <div className="font-spatial text-[1.375rem] leading-none tracking-[-0.5px] md:text-[2rem]">
                      {activeVariant?.preTitle && (
                        <p className="font-extrabold">
                          {activeVariant.preTitle}
                        </p>
                      )}
                      <p className="font-medium">{heading}</p>
                      {activeVariant?.postTitle && (
                        <p className="font-extrabold">
                          {activeVariant.postTitle}
                        </p>
                      )}
                    </div>
                    {activeVariant?.featureList && (
                      <ul className="space-y-1">
                        {activeVariant.featureList.map(({ feature }) => (
                          <li
                            key={feature}
                            className="flex items-center space-x-2.5 text-base"
                          >
                            <CheckCircleIcon className="h-6 w-6" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                    <div className="flex flex-wrap items-center justify-center gap-2">
                      <Button
                        label="Try Mob for free"
                        theme="light"
                        onClick={(e) => {
                          e.stopPropagation();

                          posthog.capture("paywall_module_clicked");

                          pushEvent("paywallModuleButtonSelected", {
                            Paywall_type: variant,
                            Paywall_Message: " Unlock the offer",
                            Paywall_Heading: heading,
                          });

                          pushEvent("paywall_cta_clicked", {
                            paywallType: "recipe",
                            paywallHeading: content?.title,
                          });

                          goToSignUp();
                        }}
                        className="!h-auto !px-6 !py-3 md:!px-12 md:!py-6"
                        icon={LockOpenIcon}
                        labelClassName="!text-base"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ReactModal>
    </PaywallContext.Provider>
  );
};
