import { useEffect, useContext, useRef, useCallback, useState } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { useRouter } from "next/router";
import { observer } from "mobx-react";
import useCurrentUser from "@/hooks/useCurrentUser";
import throttle from "lodash/throttle";
import { getDevice } from "@/lib/helpers";
import { getInitialTrafficSourceForSession } from "@/lib/getInitialTrafficSourceForSession";
import posthog from "posthog-js";
import { pushEvent } from "@/lib/gtm";
import { getUserTierFromPlan } from "@/lib/getUserTierFromPlan";

export default observer(function SessionManager() {
  const { isLoggedIn, isLoggedOut, currentUser } = useCurrentUser();
  const [isExpired, setIsExpired] = useState<boolean | null>(false);
  const {
    setSessionId,
    setGuestId,
    setIsAuthenticated,
    setUserId,
    setUtmString,
  } = useContext(SessionContext);

  const router = useRouter();

  // Ref to store the last scroll update time
  const lastScrollUpdateTimeRef = useRef(Date.now());

  // Ref to store the throttled scroll handler
  const scrollHandlerRef = useRef(null);

  const initSession = useCallback(async () => {
    try {
      const initialiseResponse = await fetch("/api/session/initialise", {
        method: "POST",
      });
      const data = await initialiseResponse.json();
      if (data.success) {
        if (
          data.sessionId &&
          data.guestId &&
          typeof data.isAuthenticated !== "undefined"
        ) {
          const now = Date.now();
          const sessionUtmString = getInitialTrafficSourceForSession(
            data.sessionStartTime
          );
          // Guest session initialized
          setSessionId(data.sessionId);
          setGuestId(data.guestId);
          setIsAuthenticated(data.isAuthenticated);
          setIsExpired(false);
          setUtmString(sessionUtmString);

          // Fire session start event
          pushEvent("session_start", {
            sessionId: data.sessionId,
            metaData: {
              eventTimestamp: now,
              sessionId: data.sessionId,
              platform: "website",
              device: getDevice(),
              pagePath: router.asPath,
              referrer: document.referrer,
              utmString: sessionUtmString,
              guestId: data.guestId,
            },
            userData: {
              identifier: currentUser ? currentUser.id : data.guestId,
              email: currentUser && currentUser.email,
              firstName: currentUser && currentUser.firstName,
              lastName: currentUser && currentUser.lastName,
              userTier: getUserTierFromPlan(currentUser ? currentUser.plan : 0),
            },
            eventData: {
              sessionStartDistinctId: currentUser?.id ?? data.guestId,
            },
          });

          posthog.capture("session_start", {
            metaData: {
              eventTimestamp: now,
              sessionId: data.sessionId,
              platform: "website",
              device: getDevice(),
              pagePath: router.asPath,
              referrer: document.referrer,
              utmString: sessionUtmString,
              guestId: data.guestId,
            },
          });
        } else {
          console.error("Unexpected response:", data);
        }
      } else {
        console.error("Initialization error:", data.error);
      }
    } catch (error) {
      console.error("Error initializing session:", error);
    }
  }, [setSessionId, setGuestId, setIsAuthenticated, setIsExpired]);

  const extendSession = useCallback(async () => {
    const extendResponse = await fetch("/api/session/extend", {
      method: "POST",
    });
    const extendResponseData = await extendResponse.json();
    if (extendResponseData.success) {
      setIsExpired(false);
    }
  }, [setIsExpired]);

  const extendOrReinitSession = useCallback(async () => {
    // check if session has expired
    const checkExpiryResponse = await fetch("/api/session/validate");
    const checkExpiryData = await checkExpiryResponse.json();
    if (checkExpiryResponse.status === 401 && !checkExpiryData.success) {
      // the session has expired and has been destroyed
      setIsExpired(true);
      await initSession();
    } else if (checkExpiryData.success) {
      // session is still active so extend session
      await extendSession();
    }
  }, [initSession, setIsExpired]);

  // Handle route changes
  useEffect(() => {
    const handleRouteChange = async () => {
      await extendOrReinitSession();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, extendOrReinitSession]);

  // Handle authentication changes
  useEffect(() => {
    if (isLoggedIn) {
      setIsAuthenticated(true);
      setUserId(currentUser.id);
      (async function () {
        await extendOrReinitSession();
      })();
    }

    if (isLoggedOut) {
      setIsAuthenticated(false);
      (async function () {
        await extendOrReinitSession();
      })();
    }
  }, [currentUser]);

  // Initialize session on component mount
  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (isMounted) {
        await initSession();
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [initSession]);

  // Scroll event handler with throttling
  useEffect(() => {
    // Define the throttled scroll handler
    const handleScroll = throttle(async () => {
      const now = Date.now();

      if (!isExpired && now - lastScrollUpdateTimeRef.current > 60 * 1000) {
        lastScrollUpdateTimeRef.current = now; // Update only when extendSession is called
        await extendOrReinitSession();
      }

      if (isExpired && now - lastScrollUpdateTimeRef.current > 1000) {
        lastScrollUpdateTimeRef.current = now;
        await extendOrReinitSession();
      }
    }, 1000); // Throttle delay of 1000ms

    // Assign the throttled handler to the ref
    scrollHandlerRef.current = handleScroll;

    // Add 'scroll' event listener with passive option for better performance
    window.addEventListener("scroll", handleScroll, { passive: true });

    // Cleanup on component unmount or when dependencies change
    return () => {
      window.removeEventListener("scroll", handleScroll);
      handleScroll.cancel(); // Cancel any pending throttled calls
    };
  }, [isExpired, extendOrReinitSession]);

  // Effect to cancel throttled scroll events when isExpired becomes false
  useEffect(() => {
    if (!isExpired && scrollHandlerRef.current) {
      scrollHandlerRef.current.cancel();
      // Optionally, reset the lastScrollUpdateTimeRef to prevent immediate session extension
      lastScrollUpdateTimeRef.current = Date.now();
    }
  }, [isExpired]);

  return null;
});
