import { SessionContext } from "@/contexts/SessionContext";
import { getUserTierFromPlan } from "@/lib/getUserTierFromPlan";
import { getDevice } from "@/lib/helpers";
import { useStores } from "hooks/useStores";
import { useContext } from "react";
import { useRouter } from "next/router";

function useGTM() {
  const { authStore } = useStores();
  const { sessionId, guestId, utmString } = useContext(SessionContext);
  const router = useRouter();

  return {
    pushEvent(eventName: string, eventData?: object) {
      if (process.env.NODE_ENV === "development")
        console.log(`[Dev] Firing GA event ${eventName}`, eventData);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: eventName,
        data: {
          sessionId,
          userData: {
            identifier: authStore.currentUser?.id ?? guestId,
            email: authStore.currentUser?.email,
            firstName: authStore.currentUser?.firstName,
            lastName: authStore.currentUser?.lastName,
            userTier: getUserTierFromPlan(authStore.currentUser?.plan ?? 0),
          },
          metaData: {
            sessionId,
            eventTimestamp: Date.now(),
            platform: "website",
            device: getDevice(),
            pagePath: router.asPath,
            referrer: document.referrer,
            guestId,
            utmString,
          },
          eventData,
        },
      });
    },
  };
}

export default useGTM;
