export const GTM_ID = process.env.NEXT_PUBLIC_GTM ?? "GTM-WQ3WM62";

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const pushEvent = (label: string, data: object): void => {
  if (process.env.NODE_ENV === "development")
    console.log(`[Dev] Firing GA event ${label}`, data);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: label, data });
};
