import recommendClient from "@/lib/algoliaRecommendClient";
import searchClient from "@/lib/algoliaSearchClient";
import qs from "qs";

export const searchStateToUrl = (searchState) =>
  `?query=${searchState.query}&page=${
    searchState.page
  }&refinementList=${JSON.stringify(searchState.refinementList || {})}`;

export const urlToSearchState = (url) => {
  const { query, page, refinementList } = qs.parse(url);
  return {
    query: query || "",
    page: page || 1,
    refinementList: refinementList ? JSON.parse(refinementList as string) : {},
  };
};

export const createSearchHref = (params: Record<string, any>) => {
  const baseURL = "/search";
  const searchParams = new URLSearchParams();

  console.log(params);

  Object.entries(params).forEach(([key, value]) => {
    // Handle the query parameter
    if (key === "query") {
      searchParams.append("q", value);
      return;
    }

    // Handle the special mapping cases
    const mappings = {
      recipeCategories: "category",
      meals: "meal",
      dietaryRequirements: "diet",
      chefs: "chef",
    };

    if (key in mappings) {
      const mappedKey = mappings[key as keyof typeof mappings];

      // Handle value when it's an object with title property
      if (value?.title) {
        if (Array.isArray(value.title)) {
          value.title.forEach((val: string) => {
            searchParams.append(mappedKey, val);
          });
        } else {
          searchParams.append(mappedKey, value.title);
        }
      }
      // Handle value when it's a direct string or array
      else {
        if (Array.isArray(value)) {
          value.forEach((val: string) => {
            searchParams.append(mappedKey, val);
          });
        } else {
          searchParams.append(mappedKey, value);
        }
      }
      return;
    }

    // Handle any other parameters directly
    if (value) {
      searchParams.append(key, value);
    }
  });

  return `${baseURL}?${searchParams.toString()}`;
};

export const fetcher = ({
  indexName = process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME,
  query = "",
  facets = ["*"],
  facetFilters = null,
  userToken = null,
  filters,
  hitsPerPage = 12,
  tagFilters = undefined,
  numericFilters,
  optionalFilters = null,
  responseFields = ["hits"],
  attributesToRetrieve = [
    "id",
    "uid",
    "slug",
    "uri",
    "title",
    "image",
    "sectionHandle",
    "chefs",
    "accessLevel",
    "dietaryRequirements",
    "time",
    "recipeCount",
    "postDate",
    "_tags",
  ],
  attributesToHighlight = [],
  analytics = false,
  getRankingInfo = true,
  shuffleSeed = -1,
  entriesToShow = 12,
  uriOnly = false,
}) => {
  const enabledProxy = true;

  const index = searchClient.initIndex(indexName);

  const options = {
    analytics,
    filters,
    facets,
    tagFilters,
    optionalFilters,
    facetFilters,
    getRankingInfo,
    hitsPerPage,
    maxValuesPerFacet: 100,
    responseFields,
    attributesToRetrieve,
    userToken,
    numericFilters,
    attributesToHighlight,
  };

  // We use the proxy to fetch data if the userToken is not provided, which at the moment, is every time
  if (!userToken && enabledProxy) {
    const queryString = new URLSearchParams({
      indexName,
      searchParams: JSON.stringify({ query, options }),
      shuffleSeed: JSON.stringify(shuffleSeed),
      entriesToShow: JSON.stringify(entriesToShow),
    }).toString();

    if (uriOnly) {
      // Respond with 200 and the API URL
      return `/api/search/proxy?${queryString}`;
    }

    // Use the proxy to fetch data
    return fetch(`/api/search/proxy?${queryString}`).then((response) =>
      response.json()
    );
  }

  // Otherwise we use the algolia client, but need to do the shuffle and slicing on the client
  return index.search(query, options);
};

export const trendingFetcher = ({
  indexName = process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME,
  responseFields = ["hits"],
  attributesToRetrieve = [
    "id",
    "uid",
    "slug",
    "uri",
    "title",
    "image",
    "sectionHandle",
    "chefs",
    "accessLevel",
    "dietaryRequirements",
    "time",
    "_tags",
  ],
  attributesToHighlight = [],
  facetName = undefined,
  facetValue = undefined,
  maxRecommendations = 12,
}) => {
  const enabledProxy = true;

  // Initialize the base queryParameters
  const queryParameters = {
    responseFields,
    attributesToRetrieve,
    attributesToHighlight,
  };

  const options = {
    indexName,
    maxRecommendations,
    queryParameters,
  };

  // Conditionally add facetName and facetValue if they are provided
  if (facetName !== undefined) {
    options.facetName = facetName;
  }
  if (facetValue !== undefined) {
    options.facetValue = facetValue;
  }

  if (enabledProxy) {
    const queryString = new URLSearchParams({
      searchParams: JSON.stringify(options),
    }).toString();

    return fetch(`/api/search/trending?${queryString}`).then((response) =>
      response.json()
    );
  }

  return recommendClient.getTrendingItems([options]);
};

export const PARAM_MAPPING = {
  section: "section.name",
  category: "recipeCategories.title",
  diet: "dietaryRequirements.title",
  meal: "meals.title",
  chef: "chefs.title",
  q: "query",
} as const;
