import * as ScrollArea from "@radix-ui/react-scroll-area";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

const DraggableScrollArea = ({
  children,
  enableSnap = true,
  onScroll = null,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef(0);
  const scrollStartX = useRef(0);
  const viewportRef = useRef(null);
  const dragTimeoutRef = useRef(null);

  const handleMouseDown = (e) => {
    dragStartX.current = e.clientX;
    scrollStartX.current = viewportRef.current.scrollLeft;
    dragTimeoutRef.current = setTimeout(() => {
      setIsDragging(true);
    }, 150);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const deltaX = e.clientX - dragStartX.current;
    viewportRef.current.scrollLeft = scrollStartX.current - deltaX;
    if (onScroll) onScroll();
  };

  const handleMouseUp = () => {
    clearTimeout(dragTimeoutRef.current); // Clear the timeout
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    clearTimeout(dragTimeoutRef.current); // Clear the timeout
    setIsDragging(false);
  };

  const handleScroll = () => {
    if (onScroll) onScroll();
  };

  return (
    <>
      <ScrollArea.Root className={clsx("w-full overflow-hidden")}>
        <ScrollArea.Viewport
          className={clsx("h-full w-full", {
            "snap-x snap-mandatory lg:snap-none": enableSnap && !isDragging,
          })}
          ref={viewportRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onScroll={handleScroll}
        >
          <div
            className={clsx("snap-start", {
              "pointer-events-none": isDragging,
            })}
          >
            {children}
          </div>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar orientation="horizontal">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </>
  );
};

export default DraggableScrollArea;
