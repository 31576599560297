import Logo from "@/components/LogoNew";
import Newsletter from "@/components/Newsletter";
import SocialLinks from "@/components/SocialLinks";
import useCurrentUser from "@/hooks/useCurrentUser";
import { footerNavigation, categoriesNavigation } from "@/lib/navigation";
import clsx from "clsx";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/router";
import Balancer from "react-wrap-balancer";

const Footer = () => {
  const { authStore } = useStores();
  const { isLoggedIn } = useCurrentUser();

  const router = useRouter();

  return (
    <footer className={clsx("Footer", "relative h-fit text-zinc-50")}>
      <div
        className={clsx(
          "Footer__content",
          "relative z-10 bg-zinc-950 py-12 xl:py-24"
        )}
      >
        <div className={clsx("px-6 lg:px-12")}>
          <div className={clsx("grid grid-cols-12 gap-x-4")}>
            <div className={clsx("col-span-12 lg:col-span-7")}>
              <div className={clsx("flex h-full flex-col")}>
                <div className={clsx("mb-10 flex items-center")}>
                  <div className={clsx("w-12 text-zinc-50")}>
                    <Logo />
                  </div>
                  <div
                    className={clsx(
                      "ml-5 max-w-3xl flex-1 border-l border-zinc-50/50 pl-5 sm:ml-6 sm:pl-6"
                    )}
                  >
                    <Balancer
                      className={clsx(
                        "font-body text-lg font-medium leading-[1.25] sm:text-xl 2xl:text-2xl 2xl:leading-none"
                      )}
                    >
                      We are on a mission to bring a love of cooking to everyone
                    </Balancer>
                  </div>
                </div>
                <div>
                  {categoriesNavigation && (
                    <div className="flex flex-wrap">
                      {categoriesNavigation.map((item) => {
                        const { label, href } = item;
                        const className = clsx("w-1/2 list-none px-1", {
                          "Footer__item--isActive": router.pathname === href,
                        });

                        if (!href) return null;

                        return (
                          <li key={item.href} className={className}>
                            <Link
                              href={href}
                              className={clsx("font-body text-sm")}
                            >
                              {label}
                            </Link>
                          </li>
                        );
                      })}
                    </div>
                  )}
                </div>

                {!isLoggedIn && (
                  <div className={clsx("mt-auto max-w-lg pt-12")}>
                    <Newsletter source="footer" />
                  </div>
                )}
              </div>
            </div>
            <div className={clsx("col-span-12 flex flex-col lg:col-span-5")}>
              <div className={clsx("space-y-6 max-lg:pt-12 lg:space-y-12")}>
                <div>
                  <SocialLinks />
                </div>
                <div>
                  {footerNavigation && (
                    <ul className={clsx("flex flex-wrap")}>
                      {footerNavigation.map((item) => {
                        const { label, href } = item;

                        const className = clsx("w-1/2", {
                          "Footer__item--isActive": router.pathname === href,
                        });

                        if (!href) return null;

                        // If the item is marked true for isLoggedOut and the user is logged in, don't show it
                        if (!item.isLoggedOut && authStore.isLoggedOut)
                          return null;

                        return (
                          <li key={item.href} className={className}>
                            <Link
                              href={href}
                              className={clsx("font-body text-sm")}
                              onClick={(e) => {
                                if (item.key === "privacySettings") {
                                  e.preventDefault();
                                  window.Didomi && window.Didomi.notice.show();
                                }
                              }}
                            >
                              {label}
                            </Link>
                          </li>
                        );
                      })}
                      {!authStore.currentUser && (
                        <>
                          <li className={clsx("w-1/2")}>
                            <Link href="/signin" className={clsx("text-sm")}>
                              Log In
                            </Link>
                          </li>
                          <li className={clsx("w-1/2")}>
                            <Link href="/premium" className={clsx("text-sm")}>
                              Try Mob for free
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default observer(Footer);
